<template>
    <modal name="addServices" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '50%'" :scrollable="true"
        class="scroltype">
        <div class="flex p-3 max-sm:h-screen bg-white">

            <form @submit.prevent="save()" class="w-full py-5 px-8">
                <div class=" flex items-center justify-between border-b border-black pb-3">
                    <h1 class="text-center text-black text-2xl">Yeni Kullanıcı</h1>
                    <button @click="hide()" class="" type="button">
                        <i class="fas fa-times text-black"></i>
                    </button>
                </div>
                <div class="mt-5 flex justify-between items-center gap-x-2 max-sm:flex-wrap">
                    <customInput v-model="username" type="text" min="2" max="255" :required="true"
                        placeholder="Kullanıcı Adı" title="Kullanıcı Adı" />
                    <customInput v-model="password" type="password" min="2" max="255" :required="true" placeholder="Şifre"
                        title="Şifre" />
                </div>
                <div class=" flex justify-between items-center gap-x-2 max-sm:flex-wrap">
                    <customInput v-model="email" type="text" min="2" max="255" :required="true" placeholder="Email"
                        title="Email" />
                    <customInput v-model="phone" type="number" :required="true" placeholder="phone" title="Telefon " />
                </div>
                <asyncButton text="Kaydet" :load="load" buttonClass=" w-full bg-black  py-1 mt-8 rounded text-white"
                    loadText="Kaydediliyor..." />
            </form>
        </div>

    </modal>
</template>
<script>
import customInput from '@/components/customInput.vue'
import asyncButton from '@/components/costumButton.vue'
import { user } from "@/networking/urlmanager";

export default {
    name: 'add-models',
    components: {
        customInput,
        asyncButton
    },
    data() {
        return {
            isModalOver: false,
            load: false,
            username: '',
            password: '',
            email: '',
            phone: '',
        }
    },
    methods: {
        show() {
            this.$modal.show('addServices');
        },
        hide() {
            this.$modal.hide('addServices');
        },
        save() {
            if (/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/.test(this.password) && this.username && this.email && this.password) {
                this.axios.post(user.add, {
                    username: this.username,
                    password: this.password,
                    email: this.email,
                    phone: this.phone,

                }).then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "BAŞARILI",
                        text: "Yeni kullanıcı eklendi",
                        showConfirmButton: true,
                        timer: 1500,
                    }).then(() => {
                        this.username = '',
                            this.password = '',
                            this.phone = '',
                            this.email = '',
                            this.load = false
                        this.$emit("refresh", true);
                        this.hide();
                    });
                }).catch((err) => {
                    this.authController(err);
                    this.load = false;
                })
            } else {
                this.$swal({
                    title: "UYARI",
                    icon: "error",
                    text: 'Lütfen tüm alanları doldurduğunuzdan emin olun.  Şifre en az 8 karakter uzunluğunda  ve en az 1 büyük harf, 1 küçük harf ve 1 rakam içermelidir.',
                    confirmButtonText: "Tamam",
                });
                this.load = false;
            }
        }
    },
}
</script>