<template>
  <div
    class="w-full top-0 shadow-base px-12 flex items-center justify-between align-middle sticky z-30 h-20 bg-[#D9D9D9] max-lg:px-8 max-md:px-5"
    align="right">
    <div class="flex items-center w-5/12 gap-6 max-md:w-1/12">
      <button @click="toggleSidebar">
        <i class="fa-solid fa-bars text-black text-2xl"></i>
      </button>
    </div>
    <div class="w-7/12 flex justify-end items-center gap-6 max-md:w-11/12 max-md:gap-4">
      <div class="relative group">
        <button class="flex items-center gap-3">
          <img src="https://source.unsplash.com/vpOeXr5wmR4/600x600"
                                class="object-cover h-9 w-9 rounded-full" alt="" />
          <div class="text-left text-black">
            <h1 class="text-base capitalize">
             {{ $store.state.userData.fullName }}
            </h1>
           
          </div>
        </button>
        <div tabindex="0"
          class="absolute bg-[#D9D9D9]  block -mr-5 w-40 right-0 textcol mt-4 shadow-md rounded invisible group-focus-within:opacity-100 group-focus-within:visible transition-all group-focus-within:mt-5">
            <button @click="signOut()"
              class="w-full  py-2  text-sm  text-left px-5 text-black hover:bg-black rounded  hover:text-white ">
              <h1><i class="fa-solid fa-right-from-bracket mr-2"></i>Çıkış Yap</h1>
            </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "nav-bar",
   props: ["toggleSidebar", "isSidebar"],
  components: {
   
  },
  methods: {
   
    signOut() {
      this.$swal({
        title: "UYARI !",
        text: "Çıkış yapmak istediğinize emin misiniz ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet, çıkış yap",
        cancelButtonText: "Hayır, vazgeç",
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.commit("signOut");
          this.$router.push("/");
        }
      });
    },
  },
    
  
  
  
};
</script>
  