<template>
  <div class="py-8 px-10  bg-white" @mousedown="filterPanel = false">
    <h1 class="text-4xl text-black">Referanslar</h1>

    <!-- ---------|Modals-------- -->
    <add ref="add" @refresh="getAll()" />
    <edit ref="edit" @refresh="getAll()" :detail="selectItem" />
    <!-- ---------|Modals-------- -->

    <div class="flex gap-3 mt-10 mb-2 max-sm:flex-wrap">
      <button class="w-2/12 max-sm:w-full py-2 bg-[#EBEBEB] rounded hover:opacity-70 hover:font-bold" @click="$refs.add.show()">
        <i class="fas fa-plus mr-2"></i> Yeni Süreç
      </button>

      <input type="text" v-model="search" @keypress.enter="() => getAll()"
        class="max-sm:h-10 w-full border border-black outline-none rounded pl-4 text-black bg-transparent placeholder:text-black"
        placeholder="Arama" />
    </div>
    <div
      class="max-sm:w-full overflow-x-auto scroltypey scroltype inline-block min-w-full shadow overflow-auto sm:rounded-lg mt-4 rounded-lg border border-[#EBEBEB] h-[470px]">
      <table class="min-w-full max-sm:w-[600px]">
        <thead class="bg-[#EBEBEB] rounded sticky top-0 z-20">
          <tr class="text-black text-sm">
            <th class="px-5 w-[10%] py-3 text-left font-semibold tracking-wider">
              #
            </th>
            <th class="px-5 w-[75%] py-3 text-left font-semibold tracking-wider">
              Başlık
            </th>

            <th class="px-5 py-3 w-[15%]"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in List" :key="index" class="text-black border-b border-gray-700 text-sm">
            <td class="px-5 py-5">
              {{ index + 1 }}
            </td>
            <td class="px-5 py-5">
             <div class="flex items-center justify-start">
                <!--  <img :src="getUrl(item.imageUrl)" class="w-10 mr-5" />-->
                <p class="whitespace-no-wrap">{{ item.name }}</p>
              </div> 
            </td>

            <td class="px-5 py-5">
              <button class="mr-4">
                <i class="fa-solid fa-pen-to-square text-base"
                  @click="() => ((selectItem = item), $refs.edit.show())"></i>
              </button>

              <!-- <button @click="deleteItem(item)"> -->
              <button @click="deleteItem(item)">
                <i class="fa-solid fa-trash text-base"></i>
              </button>
            </td>
          </tr>
          <tableLoader :load="load" :length="List.length" colspan="6" />
        </tbody>
      </table>
    </div>

  </div>
</template>
<script>
import add from "./components/add.vue";
import edit from "./components/edit.vue";
import tableLoader from "@/components/table-loader.vue";

import { referance } from "@/networking/urlmanager";
export default {
  name: "referances-page",
  components: {
    add,
    edit,
    tableLoader,

  },
  data() {
    return {
      load: false,

      search: "",
      currentPage: 0,
      totalCount: "",
      pageCount: 1,
      List: [],
      filterPanel: false,
      stateId: 1,

      selectItem: {
        rowId: "",
        name: "",
      //  image: "",
        productList: [],
      },
    };
  },
  methods: {

    getAll() {
      this.load = true;
      this.List = [];
      this.axios
        .get(referance.getAll)
        .then((res) => {
          console.log(res.data)
          this.List = res.data.data;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.load = false;
        });
    },

    deleteItem(item) {
      this.$swal({
        title: "Emin misiniz?",
        text: "Bunu geri alamazsınız!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet, sil!",
        cancelButtonText: "Hayır, vazgeç",
      }).then((result) => {
        if (result.isConfirmed) {
          this.axios
            .post(
              referance.delete, {
              rowId: item.id,

            }, {
              headers: {
                Authorization: "Bareer " + this.$store.state.userData.token,
              },
            }
            )
            .then((res) => {
              this.$swal({
                position: "center",
                icon: "success",
                title: "BAŞARILI",
                text: res.data.message,
                showConfirmButton: true,
                timer: 1500,
              });
              this.getAll();
            })
            .catch((err) => {
              console.error(err);
              const errorMessage = err.response ? err.response.data.message : err.message;
              this.$swal({
                position: "center",
                icon: "error",
                title: "HATA",
                text: errorMessage,
                showConfirmButton: true,
                confirmButtonText: "Tamam",
                timer: 1500,
              });
            });
        }
      });
    },



  },
  created() {
    this.getAll();
  },

}
</script>
