<template>
    <modal name="editServices" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '50%'" :scrollable="true"
        class="scroltype">
        <div class="flex p-3 max-sm:h-screen bg-white">

            <form @submit.prevent="editSave()" class="w-full py-5 px-8">
                <div class=" flex items-center justify-between border-b border-black pb-3">
                    <h1 class="text-center text-black text-2xl">Kapak Düzenle</h1>
                    <button @click="hide()" class="" type="button">
                        <i class="fas fa-times text-black"></i>
                    </button>
                </div>


                <div class="flex mt-5 max-sm:flex-wrap">
                    <img :src="getImageUrl()" class="w-32 mr-10" />
                    <customInput @change="r => image = r" type="file" min="2" max="255" :required="false"
                        placeholder="Kapak Dosyası " title="Resim (1350 x 900) Boyutunda olmalıdır" />
                </div>
                <!-- <div class="mt-5">
                        <ckeditor :editor="editor" v-model="name" :config="editorConfig">
                        <customInput type="textarea" min="2" max="3000" :required="false" placeholder="Açıklama" rows="5"
                            title="Açıklama" />
                    </ckeditor>
                </div> -->
                <div class="mt-5">
                    <ckeditor :editor="editor" v-model="explanation">
                        <customInput type="textarea" min="2" max="3000" :required="false" placeholder="Açıklama" rows="5"
                            title="Açıklama" />
                    </ckeditor>
                </div>

                <asyncButton text="Kaydet" :load="load" buttonClass=" w-full bg-black py-1 mt-8 rounded text-white"
                    loadText="Kaydediliyor..." />
            </form>
        </div>

    </modal>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import customInput from '@/components/customInput.vue'
import asyncButton from '@/components/costumButton.vue'
import { cover } from "@/networking/urlmanager";


export default {
    name: 'edit-models',
    props: ['detail'],
    components: {
        customInput,
        asyncButton,
    },
    data() {
        return {
            editor: ClassicEditor,

            isModalOver: false,
            load: false,
            privImage: '',
            image: '',
            name: '',
            imageId: '',
            explanation: '',
        }
    },
    methods: {

        getImageUrl() {
            return this.image ? URL.createObjectURL(this.image) : this.getUrl(this.privImage);
        },
        show() {
            setTimeout(() => {
                this.name = this.detail.name;
                this.explanation = this.detail.explanation;
                this.privImage = this.detail.imageUrl;
                this.imageId = this.detail.imageId;

            }, 50);
            this.$modal.show('editServices');
        },
        hide() {
            this.image = null;
            this.$modal.hide('editServices');
        },
        editSave() {
            this.load = true;
            const formItem = new FormData();


            formItem.append('rowId', this.detail.id)
            formItem.append('name', this.name)
            formItem.append('explanation', this.explanation)
            formItem.append('image', this.image)
            formItem.append('imageId', this.imageId)
            this.axios.post(cover.edit, formItem,
                {
                    headers: {
                        "Authorization": "Bareer " + this.$store.state.userData.token,
                        'Content-Type': 'multipart/form-data',
                    }
                })
                .then((res) => {
                
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "BAŞARILI",
                        text: res.data.message,
                        showConfirmButton: true,
                        timer: 1500,
                    })
                })
                .then(() => {
                    this.id = '',
                        this.name = '',
                        this.privImage = '',
                        this.explanation = '',
                        this.load = false
                    this.$emit("refresh", true);
                    this.hide();
                }).catch((err) => {
                    this.authController(err);
                    this.load = false;

                })
        }
    },
}
</script>