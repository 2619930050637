<template>
<div class="flex items-center ">
    <button :disabled="load" type="submit" class="px-4 h-11 w-full py-2 bgcol text-white text-[15px] rounded max-md:text-sm  " :class="[buttonClass, {'flex items-center justify-between': !imgHidden}]" @click="save">
        <img v-if="!imgHidden" :src="imgSrc" class="w-4 mr-3" alt="">
        <span v-if="!load">
            {{ text }}
        </span>
        <span v-if="load" class="flex text-xs gap-1.5 justify-center items-center ">
            <div class="w-4 h-4 rounded-full animate-spin border-2 border-solid border-white border-t-transparent shadow-md">
            </div>
            <p>{{ loadText }}</p>
        </span>
    </button>
</div>
</template>

<script>
export default {
    name: 'async-button',
    data() {
        return {}
    },
    props: {
        imgHidden: {
            type: Boolean,
            default: true
        },
        imgSrc: {
            type: String,
        },
        text: {
            type: String,
            default: 'Kaydet'
        },
        loadText: {
            type: String,
            default: "Kayıt Ediliyor"
        },
        load: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: ''
        },
    },
    methods: {
        save() {
            this.$emit('save', true)
        }
    },

}
</script>
