<template>
    <modal name="addServices" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '50%'" :scrollable="true"
        class="scroltype ">
        <div class="flex p-3 max-sm:h-screen bg-white">

            <form @submit.prevent="save()" class="w-full py-5 px-8">
                <div class=" flex items-center justify-between border-b border-black pb-3">
                    <h1 class="text-center text-black text-2xl">Yeni Kapak</h1>
                    <button @click="hide()" class="" type="button">
                        <i class="fas fa-times text-black"></i>
                    </button>
                </div>


                <div class="flex mt-5 max-sm:flex-wrap">
                    <img :src="getImageUrl()" class="w-32 mr-10" />
                    <customInput @change="r => image = r" type="file" min="2" max="255" :required="true"
                        placeholder="Kapak Dosyası" title="Resim (1350 x 900) Boyutunda olmalıdır" />
                </div>
                <!-- <div class="mt-5">
                    <customInput v-model="name" type="text" min="2" max="255" :required="false" placeholder="Başlık"
                        title="Başlık" />
                </div> -->
                <div class="mt-5">
                    <ckeditor :editor="editor" v-model="explanation" :config="editorConfig">
                        <customInput type="textarea" min="2" max="3000" :required="false" placeholder="Açıklama" rows="5"
                            title="Açıklama" />
                    </ckeditor>
                </div>
                <asyncButton text="Kaydet" :load="load" buttonClass=" w-full bg-black py-1 mt-8 rounded text-white"
                    loadText="Kaydediliyor..." />
            </form>
        </div>

    </modal>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import customInput from '@/components/customInput.vue'
import asyncButton from '@/components/costumButton.vue'
import { cover } from "@/networking/urlmanager";
import axios from 'axios'


export default {
    
    name: 'add-models',
    components: {
        customInput,
        asyncButton
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
               
                

                    
        },
            isModalOver: false,
            load: false,

            image: null,
            name: '',
            explanation: '',
        }
    },
    methods: {

        getImageUrl() {
            return this.image ? URL.createObjectURL(this.image) : require('@/assets/servicesSize.png');
        },
        show() {
            this.$modal.show('addServices');
        },
        hide() {
            this.$modal.hide('addServices');
        },
        save() {
            this.load = true;
            const formItem = new FormData();
            formItem.append('image', this.image)
            formItem.append('name', this.name)
            formItem.append('explanation', this.explanation)
            axios.post(cover.add, formItem, {
                headers: {
                    "Authorization": "Bareer " + this.$store.state.userData.token,
                    'Content-Type': 'multipart/form-data',
                }
            }).then((res) => {
                this.$swal({
                    position: "center",
                    icon: "success",
                    title: "BAŞARILI",
                    text: res.data.message,
                    showConfirmButton: true,
                    timer: 1500,
                }).then(() => {
                    this.explanation = '',
                        this.image = '',
                        this.name = '',

                        this.productList = []
                    this.load = false
                    this.$emit("refresh", true);
                    this.hide();
                });
            }).catch((err) => {

                this.authController(err);
                this.load = false;
            })



        }
    },

}
</script>