<template>
    <div class="bg-white">
        <form @submit.prevent="save()" class="w-full py-5  border border-[#EBEBEB] px-4 mt-4 rounded-lg shadow-md">
            <div id="app">

            </div>
            <div class="flex mt-5 max-sm:flex-wrap">
                <img :src="getImageUrl()" class="w-6/12 h-64 object-contain mr-10 bg-gray-100 ob" />
                <div class="w-6/12">
                    <customInput @change="r => logoFile = r" type="file" min="2" max="255" placeholder="Kapak Dosyası"
                        title="Resim (1350 x 900) Boyutunda olmalıdır" />
                </div>
            </div>
            <div class="mt-5">
                <ckeditor  :editor="editor"  v-model="$parent.detail.whyUsTitle" >
                    <customInput type="textarea" min="2" max="3000" :required="true" placeholder="Başlık" rows="5"
                        title="Açıklama" />
                </ckeditor>
            </div>
            <div   class="mt-5" >
                <ckeditor  :editor="editor"  v-model="$parent.detail.about" >
                    <customInput type="textarea" min="2" max="3000" :required="true" placeholder="Başlık" rows="5"
                        title="Açıklama" />
                </ckeditor>
            </div>
        </form>
    </div>
</template>
<script>

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import customInput from '@/components/customInput.vue'
export default {
    name: 'generalForm',
    components: {
        customInput,
    },
    data() {
        return {
            logoFile: null,
            editor: ClassicEditor,
          
            
        }
    },

    methods: {
        getImageUrl() {
            return this.logoFile ? URL.createObjectURL(this.logoFile) : this.getUrl(this.$parent.detail.whyUsImg);
        },
    },
    watch: {
        logoFile(val) {
            this.$emit('change', val);
        }
    }

}
</script>