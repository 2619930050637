<template>
    <div class="flex justify-center items-center ">
      
        <div class="w-4/12 px-20 flex h-screen items-center max-md:w-full max-md:px-10 max-sm:px-5 max-lg:px-10 bg-white">

            <form @submit.prevent="signIn()" class="w-full">
                <img src="@/assets/img/sidebar/logo.png" alt="" class="mx-auto h-64">
                <div class="">
                    <customInput v-model="username" type="text" min="3" max="40" :required="true"
                        placeholder="Kullanıcı Adı" title="Kullanıcı Adı" />
                </div>
                <div class="mt-5">
                    <customInput v-model="password" type="password" min="3" max="40" :required="true" placeholder="Parola"
                        title="Parola" />
                </div>
                <div class="flex items-center text-base gap-1 mt-4">
                    <input class="bg-gray-500 w-4 h-4 mr-1" type="checkbox" id="rememberMe" v-model="rememberMe" /><label
                        for="rememberMe" class="font-semibold  text-sm text-black">Beni
                        Hatırla</label>
                </div>
                <asyncButton text="Giriş Yap" :load="load" buttonClass=" w-full bg-black py-1 mt-8 rounded text-white"
                    loadText="Bilgileriniz Kontrol Ediliyor" />
            </form>
        </div>

    </div>
</template>
<script>
import customInput from '@/components/customInput.vue'
import asyncButton from '@/components/costumButton.vue'

import { auth } from "@/networking/urlmanager";
import axios from "axios";

export default {
    name: 'signIn-page',
    components: {
        customInput,
        asyncButton
    },
    data() {
        return {
            load: false,
            username: this.$store.state.username,
            password: '',
            rememberMe: false,
            isAuth: '',
        }
    },
    methods: {
        save() {
            this.load = true

            if (/^[^@]+@\w+(\.\w+)+\w$/.test(this.username) && this.password) {
                if ((this.username == this.auth.username) && (this.password == this.auth.password)) {
                    let data = {
                        isAuth: this.isAuth,
                        token: this.token,
                    }
                    this.load = false;
                    if (this.rememberMe) {
                        this.$store.commit('rememberMe', this.username)
                    }
                    this.$store.commit("signIn", data);
                    this.$router.push("/services");
                } else {
                    this.$swal({
                        title: "UYARI",
                        icon: "error",
                        text:
                            "Kullanıcı Adı yada Parola Hatalı",
                        confirmButtonText: "Tamam",
                    });
                    this.load = false;
                }

            } else {
                this.$swal({
                    title: "UYARI",
                    icon: "error",
                    text:
                        "E-posta formatınız hatalı lütfen kontrol edip daha sonra tekrar deneyin...",
                    confirmButtonText: "Tamam",
                });
                this.load = false;
            }
        },
        signIn() {
            if (this.username && this.password) {
                this.load = true;
                axios.post(auth.signIn, {
                    username: this.username,
                    password: this.password,
                }).then((res) => {
                    if (this.rememberMe) {
                        this.$store.commit('rememberMe', this.username)
                    }
                    this.load = false;
                    this.$store.commit("signIn", res.data.data);
                    this.$router.push("/services");
                }).catch(() => {
                    this.load = false;
                    
                    this.$swal({
                        title: "UYARI",
                        icon: "error",
                        text: 'Kulanıcı Adı veya şifre hatalı',
                        confirmButtonText: "Tamam",
                    });
                });
            } else {
                this.$swal({
                    title: "UYARI",
                    icon: "error",
                    text:
                        "E-posta formatınız hatalı lütfen kontrol edip daha sonra tekrar deneyin...",
                    confirmButtonText: "Tamam",
                });
                this.load = false;
            }
        },


    },
    created() {
        if (this.$store.state.userData.isAuth) this.$router.push("/services");
    },
}
</script>