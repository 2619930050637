<template>
    <tr class="text-black text-center" v-if="load || length == 0"  style="height: 300px !important">
      <td v-if="load" :colspan="colspan" class="p-4">
        <i class="fas fa-spinner fa-spin"></i>
        Yükleniyor...
      </td>
  
      <td v-if="!load && length == 0" :colspan="colspan" class="p-4 text-gray-300">
        Listelenebilecek Herhangi Bir Veri Bulunamadı!
      </td>
    </tr>
  </template>
  
  <script>
  export default {
    name: "table-loader",
    props: ["load", "length", "colspan"],
  };
  </script>
  
  <style></style>