<template>
    <div class="w-full ">
        <p class="text-black pb-1 text-sm">{{ title }}</p>
        <input v-if="type !== 'file' && type !== 'textarea' && type !== 'select'" class="w-full text-black bg-transparent border border-black rounded py-3 outline-none pl-3 text-xs" 
            :type="type"
            :disabled="disabled" 
            :required="required" 
            :readonly="readonly" 
            v-model="selected" 
            :placeholder="placeholder"
            :min="min" 
            :max="max"
            :minlength="min" 
            :maxlength="max" 
            :class="inputClass"
            />


        <input v-if="type == 'file' " class="w-full text-black bg-transparent border border-black rounded py-3 outline-none pl-3 text-xs" 
            :type="type"
            :required="required"
            :disabled="disabled" 
            :placeholder="placeholder"
            :class="inputClass"
            ref="fileInput"
            @change="changeFile" />
  
            
        <select v-if=" type !== 'textarea' &&type == 'select'" 
            class="w-full bg-transparent border border-amber-100 rounded py-3 outline-none pl-3 text-xs" name="" id="" 
            v-model="selected" :required="required" >
  
            <option v-for="(item,index) in selectList" 
                :key="index" 
                :value="item.value">
                {{ item.name }}
           </option>
  
        </select>
  
        <textarea v-if=" type == 'textarea'" 
            class="w-full bg-transparent border border-black text-black rounded py-3 outline-none pl-3 text-xs" 
            :class="textareaClass"
            :type="type" 
            :required="required" 
            :readonly="readonly" 
            v-model="selected" 
            :rows="rows">
        </textarea>
  
    </div>
  </template>
  <script>
  export default {
    name: "multipleInputs",
    props: [
        "title", 
        "type", 
        "value", 
        "required", 
        "disabled", 
        "min", 
        "max", 
        "readonly", 
        "textareaClass",
        "selectList",
        "placeholder",
        "inputClass",
        "rows",
     
      ],
    data() {
        return {
            selected: this.value,
            load: false,
        };
    },
    model: {
        event: "change",
        prop: "value",
    },
    methods:{
        changeFile(){
            this.$emit('change',this.$refs.fileInput.files[0])
        }
    },  
    created() {
        if (this.type != "file") {
            this.selected = this.value;
        }
    },
    watch: {
        value(newValue) {
            this.selected = newValue;
        },
        selected(val) {
            this.$emit("change", val);
        },
    },
  };
  </script>
  