import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './assets/tailwind.css'
import VueSweetalert2 from 'vue-sweetalert2';
import axios from 'axios';
import 'vue-js-modal/dist/styles.css'
import VModal from 'vue-js-modal/dist/ssr.nocss'
import 'vue-js-modal/dist/styles.css'
import './assets/css/style.css'
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(VModal, { componentName: 'modal', dynamicDefault: { draggable: true, resizable: false } })
Vue.use( CKEditor );
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('apexchart', VueApexCharts)

import 'sweetalert2/dist/sweetalert2.min.css';

const instance = axios.create();

instance.interceptors.request.use(
  config => {
    const token = store.state.userData.token;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => Promise.reject(error)
);

// Response interceptor
instance.interceptors.response.use(
  response => response,
  async error => {
    // eslint-disable-next-line no-unused-vars
    const originalRequest = error.config;

    // Eğer response 401 ise, kullanıcıyı anasayfaya yönlendir
  
    if (error.response.status === 401) {
      store.commit('signOut')
      router.push('/')
      // Orijinal isteği gerçekleştiren kodun hata bloğunu çalıştırmamak için,
      // başarılı bir yanıt döndürün
    }
    return Promise.reject(error);
  }
);


Vue.prototype.axios = instance;

Vue.mixin({
  computed: {
    isMobile() {
      return window.innerWidth < 1200;
    },
  },
  methods: {
    getUrl(url){
      
    return 'https://api.leyanglobal.com.tr/'+url;
     // return 'http://192.168.1.87:8080/'+url;
    },
    authController(error) {
      if (error.response) {
        this.$swal({
          title: 'UYARI !',
          text: error.response.data.message,
          icon: 'warning',
          confirmButtonText: 'Tamam'
        });
      } else {
        this.$swal({
          title: 'UYARI !',
          text: error,
          icon: 'warning',
          confirmButtonText: 'Tamam'
        });
      }
    }
  }
})



Vue.use(VueSweetalert2);
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
