<template>
  <div
    class="max-sm:z-[999] relative bg-[#D9D9D9] min-h-screen border-blue-600 bg-custom-dark text-white border-r-2 border-opacity-5 z-50 max-lg:fixed max-lg:mt-24 w-[22%] max-sm:mt-16 "
    :class="dataSidebar == true  
      ? 'w-[22%] maxw max-xl:w-[22%] max-lg:w-[0%] max-md:w-[0%] transition-all ease-in-out -mt-8'
      : 'w-[6%] max-xl:w-[7%] max-lg:w-[27%] max-md:w-full transition-all ease-in-out -mt-8'
      ">
    <h1 class="mx-auto  " >
      <img src="@/assets/img/sidebar/logo.png" alt="" class="mx-auto max-sm:w-8/12 "  :class="{
        'md:mt-10 max-sm:-mt-6': dataSidebar==false} "/>
    </h1> 
    <!-- LİNK -->
    <div class="">
      <div v-for="(item, index) in List" :key="index" class="px-3">
        <router-link class="w-full" :to="item.disabled ? '' : item.to"
          :class="dataSidebar == true ? 'max-lg:hidden' : 'max-lg:block '" v-if="item.authority">
          <div
            class="flex w-full items-center mt-2 border-l-2 text-black border-white px-5 max-lg:gap-5 hover:bg-black hover:bg-opacity-5 hover:!border-black hover:!text-black"
            :class="item.routerName.includes($route.name) ? active : item.disabled ? 'opacity-50' : ''
              " @click="dataSidebar = (isMobile ? !dataSidebar : dataSidebar == true)">
            <div class="flex items-center justify-center max-lg:justify-start"
              :class="dataSidebar == false ? 'lg:w-full mr-0 py-2 my-2 w-24' : 'mr-4'">
              <img  :src="item.routerName.includes($route.name) ? item.icon1 : item.icon"  class="object-contain text-black h"  :class="{
                'h-6 w-6 max-lg:w-full': dataSidebar == false,
                'h-8  w-8 max-lg:w-full ': dataSidebar,
                'opacity-100': $route.name == item.routerName,
                'opacity-60': $route.name != item.routerName,
                'opacity-20': item.disabled
              }" />
            </div>
            <button class="text-left flex justify-between py-3.5 w-full items-center font-semibold font-Arial"
            :class="{ 'hidden max-lg:inline': dataSidebar == false, }">
              {{ item.name }}
              <span class="text-red-600 opacity-100 text-sm" v-if="item.disabled">
                Yakında
              </span>
            </button>
          </div>
        </router-link>
      </div>
    </div>
    <!-- LİNK END-->
    <div class=" fixed bottom-5  " :class="widthSide" >
      <div class="flex w-full items-center mt-2 border-l-2 text-black  px-5"  :class="dataSidebar == true ? 'max-lg:hidden' : 'max-sm:block'" >
      <button @click="signOut()" class="text-left py-4 w-auto items-center font-semibold text-base flex px-4 " :class="dataSidebar == false ? '' : 'block'"  >
        <img src="@/assets/img/sidebar/signOut.svg" class="mr-4" alt=""       :class="dataSidebar == false ? 'max-sm:hidden ' : 'block'"   />
        <p :class="dataSidebar == false ? 'hidden ' : 'block'"  >Çıkış yap</p>
      </button>
      
    </div>
  </div>
  </div>
</template>
<script>
export default {
  name: "side-bar",
  props: ["isSidebar"],
  data() {
    return {
      
      dataSidebar: true,
      widthSide:  "w-[19%] max-xl:w-[22%] max-lg:w-[28%] max-lg:w-[35%] max-lg:w-full",
      active: "bg-black !border-gray-300 !text-white ",
      List: [
        // {
        //   to: "/general",
        //   name: "Genel",
        //   routerName: "general",
        //   icon: require("@/assets/img/sidebar/general.svg"),
        //   disabled: false,
        //   authority: true
        // },
        {
          to: "/services",
          name: "Hizmetler",
          routerName: ["services"],
          icon:  require("@/assets/img/sidebar/services.svg") ,
          icon1:  require("@/assets/img/sidebar/services1.svg") ,
          disabled: false,
          authority: true,
        },

        {
          to: "/cover",
          name: "Kapaklar",
          routerName: ["cover"],
          icon: require("@/assets/img/sidebar/covers.svg"),
          icon1: require("@/assets/img/sidebar/covers1.svg"),
          disabled: false,
          authority: true,
        },
        {
          to: "/referances",
          name: "Süreçlerimiz",
          routerName: ["Süreçlerimiz"],
          icon: require("@/assets/img/sidebar/referances.svg"),
          icon1: require("@/assets/img/sidebar/referances1.svg"),
          disabled: false,
          authority: true,
        },
        {
          to: "/users",
          name: "Kullanıcılar",
          routerName: ["users"],
          icon: require("@/assets/img/sidebar/customers.svg"),
          icon1: require("@/assets/img/sidebar/customers1.svg"),
          disabled: false,
          authority: true,
        },
        {
          to: "/settings",
          name: "Ayarlar",
          routerName: ["settings"],
          icon: require("@/assets/img/sidebar/Settings.svg"),
          icon1: require("@/assets/img/sidebar/Settings1.svg"),
          disabled: false,
          authority: true,
        },
        
      ],
    };
  },
  methods: {
    signOut() {
      this.$swal({
        title: "UYARI !",
        text: "Çıkış yapmak istediğinize emin misiniz ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Evet, çıkış yap",
        cancelButtonText: "Hayır, vazgeç",
      }).then((res) => {
        if (res.isConfirmed) {
          this.$store.commit("signOut");
          this.$router.push("/");
        }
      });
    },
  },
  watch: {
    isSidebar(val) {
      this.dataSidebar = val;
    },
  },
};
</script>
<style>
@media screen and (min-width: 1650px) {
  .maxw {
    width: 19%;
  }
}
</style>
