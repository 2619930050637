import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    userData: {
      token: '',
      isAuth: '',
      username: "",      
    },
  },
  getters: {
  },
  mutations: {
    signIn(state, val) {
          state.userData.token = val.token;
          state.userData.username = val.username;
          state.userData.isAuth = true;
    },
    signOut(state) {
      state.userData = {}
      state.userData.token = '';
      state.userData.isAuth = false;
    },
    rememberMe(state, val) {
      state.email = val
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
})