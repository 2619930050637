<template>
        <div  class=" bg-white" >
            <form @submit.prevent="save()" class="w-full py-5  border border-[#EBEBEB] px-4 mt-4 rounded-lg shadow-md ">
                <div class="mt-5">
                    <customInput v-model="$parent.detail.facebookUrl" type="url"  placeholder="Facebook Url"
                        title="Facebook Url" />
                </div>
                <div class="mt-5">
                    <customInput v-model="$parent.detail.instagramUrl" type="url"   placeholder="Instagram Url"
                        title="Instagram Url" />
                </div>
                <div class="mt-5">
                    <customInput v-model="$parent.detail.linkedinUrl" type="url"   placeholder="Linkedin Url"
                        title="Linkedin Url" />
                </div>

                <asyncButton text="Kaydet" :load="load" buttonClass=" w-full bg-black py-1 mt-8 rounded text-white"
                    loadText="Kaydediliyor..." />
            </form>
        </div>
</template>
<script>
import customInput from '@/components/customInput.vue'
export default {
    name: 'generalForm',
    components: {
        customInput
    },
}
</script>