import Vue from 'vue'
import VueRouter from 'vue-router'


// routes

// auth pages
import signInView from '../views/auth/signIn.vue'

// general pages
import servicesPage from '../views/services/index.vue'
import coverPage from '../views/covers/index.vue'
import referancesPage from '../views/referances/index.vue'
import settingsPage from '../views/settings/index.vue'


import usersPage from '../views/user/index.vue'
import store from '../store/index'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'signIn', 
    component: signInView,
    meta: { requiresAuth: false }
  },
  // {
  //   path: '/general',
  //   name: 'general',
  //   component: generalView,
  //   meta: { requiresAuth: true }
  // },
  {
    path: '/services',
    name: 'services',
    component: servicesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/cover',
    name: 'cover',
    component: coverPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/referances',
    name: 'referances',
    component: referancesPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: settingsPage,
    meta: { requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: usersPage,
    meta: { requiresAuth: true }
  },
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 router.beforeEach((to, from, next) => {
   if (to.matched.some(record => record.meta.requiresAuth) && !store.state.userData.isAuth) {
    next('/');
   } else {
     next()
   }
 });
export default router
