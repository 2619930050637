<template>
  <div class="py-8 px-10  bg-white" @mousedown="filterPanel = false">
    <h1 class="text-4xl text-black">Ayarlar</h1>

    <div class="w-full flex place-items-center text-black overflow-x-auto flex-wrap border-[#EBEBEB] shadow-sm">
      <button @click="stateId = 1" class="w-4/12 text-center p-4 bg-[#D9D9D9] mt-5 rounded max-sm:w-full text-lg"
        :class="stateId == 1 ? 'bg-opacity-60 text-black font-black' : 'bg-opacity-20 ' ">
        Genel
      </button>
      <!-- <button @click="stateId = 2" class="w-4/12 text-center p-4 bg-[#D9D9D9] mt-5 max-sm:w-full"
        :class="stateId == 2 ? 'bg-opacity-60 text-black' : 'bg-opacity-20'">
        Sosyal Medya
      </button> -->
      <button @click="stateId = 3" class="w-4/12 text-center p-4 bg-[#D9D9D9] mt-5 max-sm:w-full"
        :class="stateId == 3 ? 'bg-opacity-60 text-black font-black' : 'bg-opacity-20'">
        İletişim
      </button> 
      <button @click="stateId = 4" class="w-4/12 text-center p-4 bg-[#D9D9D9] mt-5 max-sm:w-full"
        :class="stateId == 4 ? 'bg-opacity-60 text-black font-black' : 'bg-opacity-20'">
        Hakkımızda
      </button>
    </div>
    <generalForm v-if="stateId == 1" @change="(r) => (logoFile = r)" />
    <socialForm v-if="stateId == 2" />
    <contactForm v-if="stateId == 3" />
    <whyUsForm v-if="stateId == 4" @change="(r) => (whyImage = r)" />
    <asyncButton text="Kaydet" :load="load" @save="save" buttonClass=" w-full bg-black py-1 mt-5 rounded text-white"
      loadText="Kaydediliyor..." />
  </div>
</template>
<script>
import generalForm from "./components/general.vue";
import socialForm from "./components/socialMedia.vue";
import contactForm from "./components/contact.vue";
import whyUsForm from "./components/whyus.vue";
import asyncButton from "@/components/costumButton.vue";
import { settings } from "@/networking/urlmanager";
export default {
  name: "settings-page",
  components: {
    generalForm,
    socialForm,
    contactForm,
    whyUsForm,
    asyncButton,
  },
  data() {
    return {
      stateId: 1,
      getLoad: false,
      load: false,
      detail: {
        facebookUrl: "",
        instagramUrl: "",
        linkedinUrl: "",
        email: "",
        phone: "",
        mapUrl: "",
        companyName: null,
        logoUrl: "",
        logoId: '',
        about: "",
        address: "",
        whyUs: "",
        whyUsTitle: "",
        whyUsImg: "",
        whyUsImgId: '',
        fax: '',
      },
      logoFile: null,
      whyImage: null,
    };
  },
  methods: {
    getAll() {
      this.getLoad = true;
      this.axios
        .get(settings.getDetail)

        .then((res) => {
          console.log(res.data.data)
          this.detail = res.data.data;
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.getLoad = false;
        });
    },
    save() {
      this.getLoad = true;

      let form = new FormData();
      form.append("facebookUrl", this.detail.facebookUrl);
      form.append("instagramUrl", this.detail.instagramUrl);
      form.append("linkedinUrl", this.detail.linkedinUrl);
      form.append("email", this.detail.email);
      form.append("phone", this.detail.phone);
      form.append("address", this.detail.address);
      form.append("mapUrl", this.detail.mapUrl);
      form.append("companyName", this.detail.companyName);
      form.append("fax", this.detail.fax);

      form.append("logo", this.logoFile);
      form.append("logoId", this.detail.logoId);

      form.append("about", this.detail.about);
      form.append("whyUs", this.detail.whyUs);
      form.append("whyUsTitle", this.detail.whyUsTitle);

      form.append("whyUsImg", this.whyImage);
      form.append("whyUsImgId", this.detail.whyUsImgId);
      console.log(this.whyImage)
      console.log(this.whyUsImgId)
      this.axios
        .post(settings.setDetail, form, {
          headers: {
            Authorization: "Bareer " + this.$store.state.userData.token,
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          this.$swal({
            position: "center",
            icon: "success",
            title: "BAŞARILI",
            text: "İşlem başarılı ayarlar başarı ile düzenlendi",
            showConfirmButton: true,
            timer: 1500,
          })
        })
        .catch((err) => {
          this.authController(err);
        })
        .finally(() => {
          this.getLoad = false;
        });
    },
  },
  created() {
    this.getAll();
  },
  watch: {
    currentPage() {
      this.getAll();
    },
    stateId() {
      this.getAll();
    },
  },
};
</script>
