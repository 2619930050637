<template>
  <div class="bg-white">
    <form
      @submit.prevent="save()"
      class="w-full py-5 border border-[#EBEBEB] px-4 mt-4 rounded-lg shadow-md"
    >
      <div class="mt-5">
        <customInput
          v-model="$parent.detail.phone"
          type="phone"
          placeholder="Telefon Numarası"
          title="Telefon Numarası"
        />
      </div>
      <div class="mt-5">
        <customInput
          v-model="$parent.detail.fax"
          type="phone"
          placeholder="Fax Numarası"
          title="Fax Numarası"
        />
      </div>
      <div class="mt-5">
        <customInput
          v-model="$parent.detail.email"
          type="email"
          placeholder="E-posta"
          title="E-posta"
        />
      </div>

      <div class="mt-5">
        <customInput
          v-model="$parent.detail.mapUrl"
          type="url"
          placeholder="Linkedin Url"
          title="Harita Url"
        />
      </div>

      <div class="mt-5">
        <customInput
          v-model="$parent.detail.address"
          placeholder="Adres"
          title="Adres"
          type="textarea"
        />
      </div>
    </form>
  </div>
</template>
<script>
import customInput from "@/components/customInput.vue";
export default {
  name: "generalForm",
  components: {
    customInput,
  }
};
</script>
