
//const baseUrl = "http://192.168.1.87:8080";
const baseUrl = "https://api.leyanglobal.com.tr/index.php";
//signIn
export const auth = {
    signIn: baseUrl + '/user/auth',
    signOut: baseUrl + '/users/sign/out'
}
//models
export const referance = {
    getDetail: baseUrl + '/referance/get/detail',
    getAll: baseUrl + '/referance/get/all',
    add: baseUrl + '/referance/add',
    edit: baseUrl + '/referance/edit',
    delete: baseUrl + '/referance/delete',
    
}
export const cover = {
    getDetail: baseUrl + '/cover/get/detail',
    getAll: baseUrl + '/cover/get/all',
    add: baseUrl + '/cover/add',
    edit: baseUrl + '/cover/edit',
    delete: baseUrl + '/cover/delete',
}
export const section = {
    getDetail: baseUrl + '/section/get/detail',
    getAll: baseUrl + '/section/get/all',
    add: baseUrl + '/section/add',
    edit: baseUrl + '/section/edit',
    delete: baseUrl + '/section/delete',
}
export const user = {
    getAll: baseUrl + '/user/get/all',
    add: baseUrl + '/user/add',
    edit: baseUrl + '/user/edit',
    delete: baseUrl + '/user/delete',
}
export const settings = {
    getDetail: baseUrl + '/settings',
    setDetail: baseUrl + '/settings',
}
export const utility = {
    getBrand: baseUrl + '/utility/get/brand'
}
