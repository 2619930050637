<template>
    <modal name="editServices" :height="isMobile ? '100%' : 'auto'" :width="isMobile ? '100%' : '50%'" :scrollable="true"
        class="scroltype">
        <div class="flex p-3 max-sm:h-screen bg-white">

            <form @submit.prevent="editSave()" class="w-full py-5 px-8">
                <div class=" flex items-center justify-between border-b border-black pb-3">
                    <h1 class="text-center text-black text-2xl">Referansı Düzenle</h1>
                    <button @click="hide()" class="" type="button">
                        <i class="fas fa-times text-black"></i>
                    </button>
                </div>
                <div class="mt-5 flex justify-between items-center gap-x-2 max-sm:flex-wrap">
                    <customInput v-model="username" type="text" min="2" max="255" :required="true"
                        placeholder="Kullanıcı Adı" title="Kullanıcı Adı" />
                    <customInput v-model="password" type="password" min="2" max="255" :required="false" placeholder="Şifre"
                        title="Şİfre" />
                </div>
                <div class=" flex justify-between items-center gap-x-2 max-sm:flex-wrap">
                    <customInput v-model="email" type="text" min="2" max="255" :required="true" placeholder="email"
                        title="Email" />
                    <customInput v-model="phone" type="number" :required="true" placeholder="Telefon" title="Telefon" />
                </div>
                <asyncButton text="Kaydet" :load="load" buttonClass=" w-full bg-black py-1 mt-8 rounded text-white"
                    loadText="Kaydediliyor." />
            </form>
        </div>
    </modal>
</template>

<script>
import customInput from '@/components/customInput.vue'
import asyncButton from '@/components/costumButton.vue'
import { user } from "@/networking/urlmanager";

export default {
    name: 'edit-models',
    props: ['detail'],
    components: {
        customInput,
        asyncButton,
    },
    data() {
        return {
            isModalOver: false,
            load: false,
            privImage: '',
            username: '',
            password: '',
            email: '',
            phone: '',
            id: '',
        }
    },
    methods: {
        show() {
            setTimeout(() => {
                this.username = this.detail.username;
                this.password = this.detail.password;
                this.email = this.detail.email;
                this.phone = this.detail.phone;
            }, 50);
            this.$modal.show('editServices');
        },
        hide() {
            this.image = null;
            this.$modal.hide('editServices');
        },
        editSave() {
            this.load = true;
            this.axios.post(user.edit,
                {
                    rowId: this.detail.id,
                    username: this.username,
                    password: this.password,
                    phone: this.phone,
                    email: this.email,
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "BAŞARILI",
                        text: 'İŞlem başarılı',
                        showConfirmButton: true,
                        timer: 1500,
                    })
                })
                .then(() => {
                    this.load = false
                    this.$emit("refresh", true);
                    this.hide();
                }).catch((err) => {
                    this.authController(err);
                    this.load = false;
                })
        }
    },
}
</script>