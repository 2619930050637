<template>
  <div id="app" class="flex min-h-screen w-full  relative bg-white">
    <sidebar :isSidebar="isSidebar" v-if="$store.state.userData.isAuth" :class="[
      $route.name == 'notFound' && 'hidden',
      $store.state.isMeetingBar == false ? $route.name == 'meeting' && 'hidden transition-all ease-in-out' : 'transition-all ease-in-out ',
      $store.state.isMeetingBar == false ? $route.name == 'meetingLive' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out',
      $store.state.isMeetingBar == false ? $route.name == 'assistan' && 'hidden transition-all ease-in-out' : ' transition-all ease-in-out'
    ]" />
    <div class="w-full max-h-screen overflow-y-auto ">
      <navbar :toggleSidebar="toggleSidebar" :isSidebar="isSidebar" v-if="$store.state.userData.isAuth" :class="[
        $route.name == 'notFound' && 'hidden',
        $route.name == 'meeting' && 'hidden',
        $route.name == 'meetingLive' && 'hidden',
        $route.name == 'assistan' && 'hidden'
      ]" />
      <router-view />
    </div>
  </div>
</template>
<script>
import navbar from '@/partitions/navbar.vue'
import sidebar from '@/partitions/sidebar.vue'
export default {
  name: 'app',
  data() {
    return {
      isSidebar: true,
    }
  },
  components: {
    navbar,
    sidebar
  },
  methods: {
    toggleSidebar() {
      this.isSidebar = !this.isSidebar,
        this.$store.commit("setIsSidebar", this.isSidebar);
    },
  }
}
</script>